<template>
  <div>
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        has-add
        @onAdd="$router.push('./add?id=3')"
        @onChangePage="handleCurrentChange"
        @onExportData="exportData"
        @onHandle="handleEdit"
        @onReset="searchConfig[4].options=[]"
        @onSearch="onSearch"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {deepCopy} from "@/utils/l-tools";
import tools from "@/utils/tools";

export default {
  _config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: "student_name",
          placeholder: "搜索学生姓名"
        },
        {
          prop: "student_no",
          placeholder: "搜索学生学号"
        },
        {
          prop: "school_id",
          tag: 'select',
          placeholder: "筛选报名校区",
          label: 'school_name',
          value: 'id',
          options: [],
          change: (val, search) => {
            this.getClass(search)
          }
        },
        {
          prop: "grade_id",
          tag: 'select',
          placeholder: "筛选年级",
          label: 'name',
          value: 'id',
          options: [],
          change: (val, search) => {
            this.getClass(search)
          }
        },
        {
          prop: "class_id",
          tag: 'select',
          placeholder: "筛选行政班",
          label: 'class_name',
          value: 'class_id',
          options: [],
        },
        {
          prop: "transfer_school_id",
          tag: 'select',
          placeholder: "筛选转学校区",
          label: 'school_name',
          value: 'id',
          options: [],
        },
      ],
      tableData: [],
      total: 0,
      tableConfig: [
        {"prop": "student_name", "label": "学生姓名"},
        {"prop": "student_no", "label": "学生学号"},
        {"prop": "school_name", "label": "报名校区"},
        {"prop": "grade_name", "label": "年级"},
        {"prop": "class_name", "label": "行政班"},
        {"prop": "transfer_school_name", "label": "转学校区"},
        {"prop": "transfer_time", "label": "转学时间"},
        {"prop": "handle", label: "操作", width: "180rem", handle: true,},
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.$_axios.get("/site/school").then(res => {
      let {data} = res.data;
      if (data.length === 0) return this.searchConfig[2].options = []
      data.unshift({
        id: "0",
        school_name: "全部"
      })
      this.searchConfig[2].options = data
      this.searchConfig[5].options = data
    })
    this.$_axios.get("/site/grades").then(res => {
      let {data} = res.data;
      if (data.length === 0) return this.searchConfig[3].options = []
      data.unshift({
        id: "0",
        name: "全部"
      })
      this.searchConfig[3].options = data
    })
    this.getData()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  methods: {
    getData(val = {}) {
      this.loading = true;
      let params = {...this.search, page: this.page, ...val}
      if (!val.type) this.tableData = [];
      this.$_axios2('api/student/student-adjust/transfer', {params}).then(res => {
        if (!!params.type) return tools.download('转学名单', res.data)
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false;
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row, handle) {
      if (handle.type === 'view') {
        this.$router.push('./look?id=' + row.id + '&type=3')
      }
    },

    handleCurrentChange(val) {
      this.$store.commit("setPage", val);
      this.getData()
    },
    getClass(val) {
      if (val?.school_id && val?.grade_id) {
        let params = deepCopy(val)
        this.$_axios.get("/site/role-admin-class", {params}).then(res => {
          let {data} = res.data;
          if (data.length === 0) return this.searchConfig[5].options = data
          data.unshift({
            class_id: "0",
            class_name: "全部"
          })
          this.searchConfig[4].options = data
        })
      }
    },
    exportData() {
      this.getData({type: 1})
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
